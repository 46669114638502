import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Location from "../components/location"
import { graphql } from "gatsby"

export const query = graphql`
  query HomePageContent {
    page: sanityPage(_id: { eq: "119cfcec-df29-47f2-8a34-a036319492c2" }) {
      id
      title
      _rawContent
    }
  }
`

const IndexPage = props => {
  const { data } = props

  return (
  <Layout isHome>
    <SEO title="Wedding Flower Arrangements and Design" />
    <section className="border-t-4 border-green-900 px-2 md:px-0">
      <div className="container mx-auto md:flex py-8 md:py-16 items-center">
        <div className="w-full md:w-1/5">
          <Location />
        </div>
        <div className="w-full md:w-4/5 md:flex text-sm justify-end md:pl-8">
          <div className="bg-grunge bg-green-900 text-white text-center md:ml-12 w-full md:w-1/3 p-8 rounded-lg flex flex-col justify-center mb-4 md:mb-0">
            <span className="uppercase text-white border-b border-white mb-4 pb-2 inline-block w-full text-center text-lg">Relationship</span>
            <p>We believe that the best work comes from developing an open, honest &amp; personal relationship with our clients.</p>
          </div>
          <div className="bg-grunge text-white text-center md:ml-12 w-full md:w-1/3 p-8 rounded-lg flex flex-col justify-center mb-4 md:mb-0" style={{backgroundColor: '#ad8495'}}>
            <span className="uppercase text-white border-b border-white mb-4 pb-2 inline-block w-full text-center text-lg">Design</span>
            <p>In order to create the best possible floral design. It takes listening to the brides to understand their needs.</p>
          </div>
          <div className="bg-grunge bg-teal-700 text-white text-center md:ml-12 w-full md:w-1/3 p-8 rounded-lg flex flex-col justify-center mb-4 md:mb-0" style={{backgroundColor: '#84a9ad'}}>
            <span className="uppercase text-white border-b border-white mb-4 pb-2 inline-block w-full text-center text-lg">Detail</span>
            <p>Stacey's attention to detail is what makes Willow Branch Flowers &amp; Design so successful.</p>
          </div>
        </div>
      </div>
    </section>

    <section className="bg-gray-400 bg-textured-content border-t border-b py-6 md:py-12 border-gray-400">
        <div className="container mx-auto">
          <h1 className="font-serif text-2xl md:text-3xl pb-6 pl-4">Mooresville Wedding Flower Arrangements and Design</h1>
          <div className="two-column-text leading-loose">
            {data.page._rawContent.map((block, idx) => {
              return (
                <p key={idx} className="mb-6 px-4">{block.children[0].text}</p>
              )
            })}
          </div>

        </div>
    </section>

  </Layout>
  )
}

export default IndexPage
